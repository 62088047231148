<template>
    <v-app id="menuLateral">
        <Menu :hide="!hideMenu" />
        <span>
            <div :style="'position: fixed; width: 100%; height: 100%; background-color: #ddd; opacity: 0.6; z-index: 9999999;'
                + (loading ? '' : 'display: none;')">
                <v-img
                    width="200"
                    :src="require('@/assets/loading.gif')"
                    style="top: 30%; margin: auto; z-index: 999999;"
                />
            </div>

            <slot name="principal" />

            <confirm ref="confirm" />
        </span>
    </v-app>
</template>

<script>
import Menu from '@/components/Menu';
import Confirm from '@/components/Confirm';
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'BaseContainer',

    components: {
        Menu,
        Confirm,
    },

    props: ['hideMenu'],

    data: () => ({
        loading: false,
    }),

    mounted() {
        this.$root.$on('loading', load => (this.setLoading(load)));
        this.$root.$confirm = this.$refs.confirm.open;

        this.$vuetify.theme.dark = true;

        this.checkUser();
    },

    computed: {
        ...mapState([
            'usuario',
        ]),
    },

    methods: {
        ...mapMutations({
            setUsuario: 'setUsuario',
        }),

        setLoading(loading) {
            this.loading = loading;
        },

        async checkUser() {
            if (this.$route.path === '/login' || this.usuario.id) {
                return;
            }

            try {
                const { data } = await this.$http.post('usuarios/get-auth');
                this.setUsuario(data.data);
            } catch (e) {
                console.log(e)
            }
        },
    },
};
</script>
