<template>
    <span v-if="hide">
        <v-navigation-drawer v-model="primaryDrawer.model" :temporary="true" app overflow>
            <v-list dense>

                <v-list-item link to="">
                    <v-list-item-content>
                        <v-list-item-title 
                            v-text="`Bem vindo, ${usuario.name}`" 
                        />
                        <v-list-item-subtitle 
                            v-text="usuario.email" 
                        />
                        <v-list-item-subtitle/>
                    </v-list-item-content>
                </v-list-item>

                <v-divider dark class="my-4"/>

                <v-list-item link to="/">
                    <v-list-item-action>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Inicio</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/empresas">
                    <v-list-item-action>
                        <v-icon>mdi-home-city</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Empresas</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/usuarios">
                    <v-list-item-action>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Usuários</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>  

                <v-list-item link to="/contatos">
                    <v-list-item-action>
                        <v-icon>mdi-whatsapp</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Leads</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item link to="/financeiro">
                    <v-list-item-action>
                        <v-icon>mdi-cash</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Financeiro</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

                <v-list-item link to="/anuncios">
                    <v-list-item-action>
                        <v-icon>mdi-advertisements</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Anúncios</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item link to="/planos">
                    <v-list-item-action>
                        <v-icon>mdi-briefcase-edit-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Planos</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

                <v-list-item link to="/notificacoes">
                    <v-list-item-action>
                        <v-icon>mdi-bell</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Notificações</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/novidades">
                    <v-list-item-action>
                        <v-icon>mdi-bullhorn</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Novidades</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-action>
                            <v-icon>mdi-file-chart</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Relatórios</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <div style="margin-left: 20px;">
                        <v-list-item link to="/relatorio-contatos">
                            <v-list-item-action>
                                <v-icon>mdi-chart-areaspline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Contatos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-list-group>

                <v-divider dark class="my-4"/>

                <v-list-item @click="logout">
                    <v-list-item-action>
                        <v-icon color="red">mdi-logout</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Sair</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app>
            <v-app-bar-nav-icon @click.stop="primaryDrawer.model = !primaryDrawer.model"/>
            <v-spacer />
            <div @click="goToHome()">
                <v-icon>mdi-home</v-icon> Inicio
            </div>
            <v-spacer />
            <div>
                <v-icon>mdi-account</v-icon> {{ usuario.name }}
            </div>
            <v-spacer />
            <div>
                <v-btn @click="logout" color="error" icon>
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
            </div>
        </v-app-bar>
    </span>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Menu',

    props: ['hide'],

    data: () => ({
        primaryDrawer: {
            model: null,
        },
    }),

    computed: {
        ...mapState([
            'usuario',
        ]),
    },

    methods: {
        logout() {
            if (confirm('Deseja realmente sair?')) {
                sessionStorage.clear();
                this.$router.push('/login');
            }
        },

        goToHome() {
            if (this.$route.path == '/') {
                this.$root.$emit('listarComandas');
            } else {
                this.$router.push('/');
            }
        },
    }
};
</script>
